import {envConfig} from './constants'
export default {
  oidc: {
    clientId: `${envConfig.PORTAL_OKTA_CLIENT_ID}`,
    issuer: `${envConfig.PORTAL_OKTA_ISSUER}`,
    redirectUri: `${window.location.origin}/oktaCallback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  },
};
