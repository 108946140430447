import { actionTypes, uploadAndDownloadStates } from '../utils/constants';
import initialState from './initialState';
const { runInfo } = initialState;
/**
 * User Reducer
 *
 * @param {object} state the state
 * @param {object} action the action that executed
 */
export default function RunListReducer(state = runInfo, action) {
  switch (action.type) {
    case actionTypes.GET_USER_RUN_LIST_SUCCESS: {
      return {
        ...state,
        runList: action.runList.data,
      };
    }
    case actionTypes.GET_LAST_X_DAYS:
      return {
        ...state,
        xDays: action.days,
      };
    case actionTypes.GET_USER_RUN_LIST_FAILED:
      return state;
    case actionTypes.GET_ORDER_SHEET_UPLOAD_SUCCESS: {
      const { status, payLoad, request } = action.response;
      if (request) {
        return {
          ...state,
          uploadState: status,
          uploadMsgsInfo: payLoad,
          uploadData: request,
          runId: request.runId,
          uploadStatusModalVisible: true,
          uploadOrderModalVisible: false,
        };
      }
      return {
        ...state,
        uploadState: status,
        uploadStatusModalVisible: true,
        uploadOrderModalVisible: false,
      };
    }
    case actionTypes.GET_ORDER_SHEET_SIMPLE_UPLOAD_SUCCESS: {
      const { status } = action.response;
      return {
        ...state,
        uploadState: status,
        uploadStatusModalVisible: false,
        uploadOrderModalVisible: false,
      };
    }

    case actionTypes.GET_ORDER_SHEET_UPLOAD_FAILED: {
      const { FAILED_STATE } = uploadAndDownloadStates;
      return {
        ...state,
        uploadState: FAILED_STATE,
        uploadOrderModalVisible: false,
      };
    }

    case actionTypes.GET_ORDER_SHEET_UPLOAD_VALIDATION_FAILED: {
      const { VALIDATION_FAILED_STATE } = uploadAndDownloadStates;
      return {
        ...state,
        uploadState: VALIDATION_FAILED_STATE,
        uploadOrderModalVisible: false,
      };
    }
    case actionTypes.GET_UPLOAD_MSG_INFO_SUCCESS: {
      return {
        ...state,
        uploadMsgsInfo: action.response,
      };
    }
    case actionTypes.GET_UPLOAD_MSG_INFO_FAILED:
      return { ...state };

    case actionTypes.GET_UPLOAD_STATUS_MODAL_IS_VISIBLE: {
      const { ind, runId, currency, runDate } = action.options;
      return {
        ...state,
        uploadStatusModalVisible: ind,
        runId,
        currency,
        runDate,
              };
    }
    case actionTypes.GET_UPLOAD_ORDER_SHEET_MODAL_IS_VISIBLE:
    {
      const { ind, runId, currency, runDate, instructionTextFromRunList } = action.options;
       return {
        ...state,
        uploadOrderModalVisible: ind,
        runId,
        currency,
        runDate,
        instructionTextFromRunList,
      };
    }
    case actionTypes.GET_UPLOAD_MODAL_IS_History: {
      const { modalState, runId, currency, runDate } = action.history;
      return {
        ...state,
        uploadState: modalState,
        runId,
        uploadStatusModalVisible: true,
        currency,
        runDate,
      };
    }
    case actionTypes.RESET_UPLOAD_MSG_INFO_ARRAY:{
      const { NEW_STATE } = uploadAndDownloadStates;
      return {
        ...state,
        uploadMsgsInfo: { },uploadState: NEW_STATE,
      }};
    case actionTypes.RESET_ORDER_UPLOAD_STATE:
        const { NEW_STATE } = uploadAndDownloadStates;
        return {
          ...state,
          uploadState: NEW_STATE,
        };
    case actionTypes.DOWNLOAD_SUCCESS: {
      return {
        ...state,
        orderDownloadState: action.state,
      };
    }
    case actionTypes.DOWNLOAD_FAILED: {
      return {
        ...state,
        orderDownloadState: action.state,
      };
    }
    case actionTypes.DOWNLOAD_TRADE_REPORT_SUCCESS: {
      return {
        ...state,
        tradeReportDownloadState: action.state,
      };
    }
    case actionTypes.DOWNLOAD_TRADE_REPORT_FAILED: {
      return {
        ...state,
        tradeReportDownloadState: action.state,
      };
    }
    case actionTypes.GET_SYSTEM_CONFIG: {
      return {
        ...state,
        sourceSystemConfig: action.sourceSystemConfig,
      }
    }


    default:
      return state;
  }
}
