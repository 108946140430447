import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { lazyWithPreload } from '../../utils/lazyLoader';
import DelayedFallback from '../FallBack/DelayedFallback';
import AppWithRouterAccess from './AppWithRouterAccess';

const ErrorBoundary = lazyWithPreload(() => import(/* webpackChunkName: "ErrorBoundary" */ '../Error/ErrorBoundary'));

const App = () => (
  <Suspense fallback={<DelayedFallback />}>
    <ErrorBoundary>
      <BrowserRouter forceRefresh>
        <AppWithRouterAccess />
      </BrowserRouter>
    </ErrorBoundary>
  </Suspense>
);

export default App;
