import { actionTypes } from '../utils/constants';

export function changeAboutIsLoadingAction(aboutModalVisible) {
  return {
    type: actionTypes.GET_ABOUT_IS_VISIBLE,
    aboutModalVisible,
  };
}

export function changeContactUsLoadingAction(contactModalVisible) {
  return {
    type: actionTypes.GET_CONTACT_IS_VISIBLE,
    contactModalVisible,
  };
}

export function changeAgreementLoadingAction(agreementModalVisible) {
  return {
    type: actionTypes.GET_AGREEMENT_IS_VISIBLE,
    agreementModalVisible,
  };
}

export function changeAgreementVisible(agreementModalVisible) {
  return (dispatch) => dispatch(changeAgreementLoadingAction(agreementModalVisible));
}

export function changeContactUsVisible(contactModalVisible) {
  return (dispatch) => dispatch(changeContactUsLoadingAction(contactModalVisible));
}

export function changeAboutUsVisible(aboutModalVisible) {
  return (dispatch) => dispatch(changeAboutIsLoadingAction(aboutModalVisible));
}

export function changeIsLoadingAction(isLoading) {
  return { type: actionTypes.GET_IS_LOADING, isLoading };
}

export function changeIsLoading(isLoading) {
  return (dispatch) => dispatch(changeIsLoadingAction(isLoading));
}
