import { actionTypes } from '../utils/constants';
import initialState from './initialState';

/**
 * User Reducer
 *
 * @param {object} state the state
 * @param {object} action the action that executed
 */
export default function UserReducer(state = initialState.userConfig, action) {
  switch (action.type) {
    case actionTypes.GET_OKTAPOSTINGID:
      return { ...state, traderPostingId: action.oktaPostingId };
    case actionTypes.GET_USERNAME:
        return { ...state, username: action.username };
    default:
      return state;
  }
}
