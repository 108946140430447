import { notification } from 'antd';
import { changeIsLoading } from '../actions/UIActions';

export const showErrorNotification = (message, description, duration) => {
  notification.error({ message, description, duration: duration || 0 });
};

export const showSuccessNotification = (message, description, duration) => {
  notification.success({ message, description, duration: duration || 0 });
};

export const showWarningNotification = (message, description, duration) => {
  notification.warning({ message, description, duration: duration || 0 });
};

export const showInfoNotification = (message, description, duration) => {
  notification.info({ message, description, duration: duration || 0 });
};

export const showNotification = (errorType, message, description, duration) => {
  changeIsLoading(false);
  if (errorType === 'ERROR') {
    notification.error({ message, description, duration: duration || 0 });
  }
  if (errorType === 'WARNING') {
    notification.warning({ message, description, duration: duration || 0 });
  }
  if (errorType === 'INFO') {
    notification.info({ message, description, duration: duration || 0 });
  }
};
