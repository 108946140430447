import { actionTypes } from '../utils/constants';
import initialState from './initialState';

/**
 * User Reducer
 *
 * @param {object} state the state
 * @param {object} action the action that executed
 */

export default function orderStorageDataReducer(state = initialState.fileDownloadData, action){
    switch (action.type) {
        case actionTypes.GET_STORAGE_ORDERDATA_SUCCESS:
          return {...state, s3OrderUploadData:action.s3OrderUploadData, error:false};
        case actionTypes.GET_STORAGE_ORDERDATA_FAILED:
          return {...state, error: true};
        default:
          return state;
      }
}
