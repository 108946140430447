import { combineReducers } from 'redux';
import runInfo from './runListReducer';
import userConfig from './UserReducer';
import uiOperation from './uiOperationReducer';
import sourceSystemConfigs from './sourceSystemConfigsReducer';
import fileDownloadData from './orderStorageDataReducer';
import processedToggleResponse from './processedToggleReducer';
import versionInfo from './versionInfoReducer';
import instructionModal from './instructionTextReducer';

const rootReducer = combineReducers({
  runInfo,
  userConfig,
  uiOperation,
  sourceSystemConfigs,
  fileDownloadData,
  processedToggleResponse,
  versionInfo,
  instructionModal,
});

export default rootReducer;
