import { uploadAndDownloadStates } from '../utils/constants';

const { NEW_STATE } = uploadAndDownloadStates;
const initialState = {
  versionInfo : null,
  sourceSystemConfigs: [],
  runInfo: {
    runList: [],
    xDays: 1,
    sourceSystemConfig: null,
    uploadStatusModalVisible: false,
    uploadOrderModalVisible: false,
    uploadState: NEW_STATE,
    runId: null,
    currency: null,
    runDate: null,
    instructionTextFromRunList: null,
    // form data
    uploadData: {},
    // order upload validation messages
    uploadMsgsInfo: {},
    orderDownloadState: NEW_STATE,
    tradeReportDownloadState: NEW_STATE,
  },
  userConfig: {
    traderPostingId: null,
    username: null,
  },
  addUpdateUserStatus: {},
  uiOperation: {
    aboutModalVisible: false,
    contactModalVisible: false,
    agreementModalVisible: false,
    isLoading: false,
    errorInfo: {
      errorType: null,
      messageType: null,
      message: null,
      debugMessage: null,
    },
  },
  fileDownloadData:{
    s3OrderUploadData : [],
    error: false,
  },
  instructionModal:{
    instructionTextModalVisible: false,
    instructionText: null,
  },
  processedToggleResponse : null,

};

export default initialState;
