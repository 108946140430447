import { USER_GROUPS_KEY, validPathsPerGroup, validGroups } from './constants';

export const getUserGroups = () => {
  const serializedState = localStorage.getItem('userGroups');
  if (serializedState !== null) {
    return JSON.parse(serializedState);
  }
  return [];
};

export const isUserAuthorised = (pathName) => {
  if (getUserGroups().length > 0) {
    return getUserGroups()
      .some(group => {
        return validPathsPerGroup[group].indexOf(pathName) >= 0;
      });
  }
};

export const clearUserGroups = () => {
  localStorage.removeItem('userGroups');
};

export const saveUserGroups = (userGroups) => {
  if (getUserGroups().length === 0 && userGroups) {
    localStorage.removeItem(USER_GROUPS_KEY);
  let filteredUserGroups = userGroups.filter((g)=> {return validGroups.indexOf(g) >=0});
    localStorage.setItem(USER_GROUPS_KEY, JSON.stringify(filteredUserGroups));
  } else if (!userGroups) {
    let defaultArray = [];
    localStorage.setItem(USER_GROUPS_KEY, JSON.stringify(defaultArray));
  }
};
