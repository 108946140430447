import { actionTypes } from '../utils/constants';
import { showErrorNotification } from '../utils/notifications';
import initialState from './initialState';

/**
 * User Reducer
 *
 * @param {object} state the state
 * @param {object} action the action that executed
 */

export default function sourceSystemConfigReducer(state = initialState.sourceSystemConfigs, action){
    switch (action.type) {
        case actionTypes.GET_SYSTEM_CONFIGS_SUCCESS:
        var error = action.sourceSystemConfigs.data.errorInfo
        if (error != null && error.message != null) {
          showErrorNotification(
            action.sourceSystemConfigs.data.errorInfo.message
          );
        }  
        return action.sourceSystemConfigs.data.payLoad;

        default:
          return state;
      }
}
