import { actionTypes } from '../utils/constants';
import initialState from './initialState';

/**
 * Instruction Text Reducer -. used to change change visibility and text for instruction text
 *
 * @param {object} state the state
 * @param {object} action the action that executed
 */

export default function instructionTextReducer(state = initialState.instructionModal, action){
  switch (action.type) {
    case actionTypes.GET_INSTRUCTION_TEXT_IS_VISIBLE:
      return {...state, instructionText: action.text, instructionTextModalVisible: action.visible};
    default:
      return state;
  }
}
