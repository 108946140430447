import { actionTypes } from '../utils/constants';
import initialState from './initialState';

/**
 * User Reducer
 *
 * @param {object} state the state
 * @param {object} action the action that executed
 */
export default function UiOperationReducer(state = initialState.uiOperation, action) {
  switch (action.type) {
    case actionTypes.GET_ABOUT_IS_VISIBLE:
      return { ...state, aboutModalVisible: action.aboutModalVisible };
    case actionTypes.GET_CONTACT_IS_VISIBLE:
      return { ...state, contactModalVisible: action.contactModalVisible };
    case actionTypes.GET_AGREEMENT_IS_VISIBLE:
      return { ...state, agreementModalVisible: action.agreementModalVisible };
    case actionTypes.GET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_SYSTEM_CONFIGS_IGNORE:
    return {...state, isLoading: false, errorInfo: {
        errorType: "ignore",
        messageType: "Ignore",
        message: null ,
        debugMessage: null,
      },};
    case actionTypes.GET_SYSTEM_CONFIGS_FAILED:
      var message = action.sourceSystemConfigs.data.errorInfo.message;
      return  {
        ...state,
        isLoading: false,
        errorInfo: {
          errorType: "error",
          messageType: "Error",
          message: message != null ? message : "Error: Incorrect Posting ID. Please contact the MATCHBOOK Sales Team." ,
          debugMessage: null,
        },
    }
    case actionTypes.VERSION_INFO_FAILED:
      return  {
        ...state,
        isLoading: false,
        errorInfo: {
          errorType: "error",
          messageType: "Error",
          message: "Error getting version info of the application.",
          debugMessage: null,
        },
    }
    default:
      return state;
  }
}
