import { Route, Switch, useHistory } from 'react-router-dom';
import React, { lazy } from 'react';
import { LoginCallback, Security } from '@okta/okta-react';
import { lazyWithPreload } from '../../utils/lazyLoader';
import authClient from '../../utils/AuthorisationClient';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '../Error/NotFound'));
const RunList = lazyWithPreload(() => import(/* webpackChunkName: "UserList" */ '../Run/RunListContainer'));
const Login = lazyWithPreload(() => import(/* webpackChunkName: "Login" */ '../Login/Login'));
const FileDownloader = lazyWithPreload(() => import(/* webpackChunkName: "Login" */ '../FileDownload/FileDownloader'));
const UnAuthorised = lazy(() => import(/* webpackChunkName: "Login" */ '../Error/UnAuthorised'));

const AppWithRouterAccess = () => {
  const history = useHistory();
  const onAuthRequired = () => {
    history.push('/');
  };

  return (
    <Security
      oktaAuth={authClient.getAuthClient()}
      onAuthRequired={onAuthRequired}
    >
      <Switch>
        <Route path="/" exact component={Login}/>
        <PrivateRoute exact path="/runsList" component={RunList}/>
        <PrivateRoute exact path="/fileDownloader" component={FileDownloader}/>
        <Route path="/oktaCallback" component={LoginCallback} />
        <Route path="/UnAuthorised" exact component={UnAuthorised}/>
        <Route path="*" exact component={NotFound}/>
        <Route path="/" exact component={Login} />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </Security>
  );
};
export default AppWithRouterAccess;
