import { OktaAuth } from '@okta/okta-auth-js';
import config from './config';

class AuthorisationClient {
  constructor(oidcConfig) {
    if (!AuthorisationClient.instance) {
      this._authClient = new OktaAuth(oidcConfig);
      AuthorisationClient.instance = this;
    }
    return AuthorisationClient.instance;
  }

  getAuthClient() {
    return this._authClient;
  }

  getAccessToken() {
    return this._authClient.getAccessToken();
  }
}

const authClient = new AuthorisationClient({ ...config.oidc });
Object.freeze(authClient);

export default authClient;
