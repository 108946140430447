import { actionTypes } from '../utils/constants';
import initialState from './initialState';

/**
 * User Reducer
 *
 * @param {object} state the state
 * @param {object} action the action that executed
 */

export default function processedToggleReducer(state = initialState.processedToggleResponse, action){
    switch (action.type) {
        case actionTypes.SAVE_PROCESSED_SUCCESS:
          return action.processedToggleResponse;
        case actionTypes.SAVE_PROCESSED_FAILED:
          return state;
        default:
          return state;
      }
}
