import React from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {SecureRoute} from '@okta/okta-react';
import {isUserAuthorised} from '../../utils/PortalStorage';

const PrivateRoute = ({
                          component: Component,
                          ...rest
                      }) => {

    const isAuthorised = (routeProps) => {
        return isUserAuthorised(routeProps.location['pathname']);
    };

    return (
        <SecureRoute
            {...rest}
            render={(routeProps) => {
                if (isAuthorised(routeProps)) {
                    return (<Component {...routeProps} />);
                }
                return (<Redirect to={{
                    pathname: '/UnAuthorised',
                    state: {from: routeProps.location}
                }}/>);
            }}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.shape().isRequired,
};

export default PrivateRoute;
