export const envConfig = window.injectedEnv;

export const actionTypes = {
  GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_FAILED: 'GET_USER_LIST_FAILED',
  GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
  GET_USER_DATA_FAILED: 'GET_USER_DATA_FAILED',
  ADD_UPDATE_USER_SUCCESS: 'ADD_UPDATE_USER_SUCCESS',
  ADD_UPDATE_USER_FAILED: 'ADD_UPDATE_USER_FAILED',
  GET_USER_RUN_LIST_SUCCESS: 'GET_USER_RUN_LIST_SUCCESS',
  GET_USER_RUN_LIST_FAILED: 'GET_USER_RUN_LIST_FAILED',
  GET_LAST_X_DAYS: 'GET_LAST_X_DAYS',
  SET_UPLOAD_DATE_TIME: 'SET_UPLOAD_DATE_TIME',
  GET_OKTAPOSTINGID: 'GET_OKTAPOSTINGID',
  GET_ORDER_SHEET_UPLOAD_SUCCESS: 'GET_ORDER_SHEET_UPLOAD_SUCCESS',
  GET_ORDER_SHEET_UPLOAD_FAILED: 'GET_ORDER_SHEET_UPLOAD_FAILED',
  GET_ORDER_SHEET_UPLOAD_VALIDATION_FAILED: 'GET_ORDER_SHEET_UPLOAD_VALIDATION_FAILED',
  GET_ABOUT_IS_VISIBLE: 'GET_ABOUT_IS_VISIBLE',
  GET_CONTACT_IS_VISIBLE: 'GET_CONTACT_IS_VISIBLE',
  GET_UPLOAD_STATUS_MODAL_IS_VISIBLE: 'GET_UPLOAD_STATUS_MODAL_IS_VISIBLE',
  GET_UPLOAD_ORDER_SHEET_MODAL_IS_VISIBLE: 'GET_UPLOAD_ORDER_SHEET_MODAL_IS_VISIBLE',
  GET_UPLOAD_MODAL_IS_History: 'GET_UPLOAD_MODAL_IS_History',
  GET_AGREEMENT_IS_VISIBLE: 'GET_AGREEMENT_IS_VISIBLE',
  GET_INSTRUCTION_TEXT_IS_VISIBLE: 'GET_INSTRUCTION_TEXT_IS_VISIBLE',
  GET_IS_LOADING: 'GET_IS_LOADING',
  GET_UPLOAD_MSG_INFO_SUCCESS: 'GET_UPLOAD_MSG_INFO_SUCCESS',
  GET_UPLOAD_MSG_INFO_FAILED: 'GET_UPLOAD_MSG_INFO_FAILED',
  RESET_UPLOAD_MSG_INFO_ARRAY: 'RESET_UPLOAD_MSG_INFO_ARRAY',
  DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILED: 'DOWNLOAD_FAILED',
  DOWNLOAD_TRADE_REPORT_SUCCESS: 'DOWNLOAD_TRADE_REPORT_SUCCESS',
  DOWNLOAD_TRADE_REPORT_FAILED: 'DOWNLOAD_TRADE_REPORT_FAILED',
  UPDATE_USER_NAME: 'UPDATE_USER_NAME',
  GET_SYSTEM_CONFIGS_SUCCESS: 'GET_SYSTEM_CONFIGS_SUCCESS',
  GET_SYSTEM_CONFIGS_FAILED: 'GET_SYSTEM_CONFIGS_FAILED',
  GET_SYSTEM_CONFIGS_IGNORE: 'GET_SYSTEM_CONFIGS_IGNORE',
  GET_SYSTEM_CONFIG: 'GET_SYSTEM_CONFIG',
  GET_USERNAME: 'GET_USERNAME',
  GET_ORDER_SHEET_SIMPLE_UPLOAD_SUCCESS: 'GET_ORDER_SHEET_SIMPLE_UPLOAD_SUCCESS',
  RESET_ORDER_UPLOAD_STATE: 'RESET_ORDER_UPLOAD_STATE',
  GET_STORAGE_ORDERDATA_SUCCESS: 'GET_STORAGE_ORDERDATA_SUCCESS',
  GET_STORAGE_ORDERDATA_FAILED: 'GET_STORAGE_ORDERDATA_FAILED',
  SAVE_PROCESSED_SUCCESS: 'SAVE_PROCESSED_SUCCESS',
  SAVE_PROCESSED_FAILED: 'SAVE_PROCESSED_FAILED',
  VERSION_INFO_SUCCESS: 'VERSION_INFO_SUCCESS',
  VERSION_INFO_FAILED: 'VERSION_INFO_FAILED',
};

export const ids = {
  NAME: 'name',
  AGE: 'age',
  ADDRESS: 'address',
  GENDER: 'gender',
};

export const api = {
  GET_RUN_LIST: `${envConfig.PORTAL_SERVICE_API_APP_URL}/matchbox-portal/runList/`,
  GET_DOWNLOAD_ORDER_SHEET: `${envConfig.PORTAL_SERVICE_API_APP_URL}/matchbox-portal/ordersheet/`,
  POST_UPLOAD_ORDER_SHEET: `${envConfig.PORTAL_SERVICE_API_APP_URL}/matchbox-portal/upload`,
  GET_UPLOAD_ORDER_INFO: `${envConfig.PORTAL_SERVICE_API_APP_URL}/matchbox-portal/orderInfo/`,
  GET_DOWNLOAD_TRADE_REPORT_SHEET: `${envConfig.PORTAL_SERVICE_API_APP_URL}/matchbox-portal/tradeReport/`,
  GET_SYSTEMCONFIGS: `${envConfig.PORTAL_SERVICE_API_APP_URL}/matchbox-portal/config/`,
  GET_STORAGE_ORDER_DATA: `${envConfig.PORTAL_SERVICE_API_APP_URL}/matchbox-portal/orderstoragedata/`,
  POST_PROCESSED_TOGGLE: `${envConfig.PORTAL_SERVICE_API_APP_URL}/matchbox-portal/processedToggle`
};

export const PORTAL_TRADER = 'MB-Trader';
export const PORTAL_MARKET_ADMIN = 'MB-Market_Admin';
export const RUN_LIST_PATH = "/runsList";
export const FILE_DOWNLOADER_PATH = "/fileDownloader";
export const USER_GROUPS_KEY = 'userGroups';
export const validGroups = [PORTAL_TRADER, PORTAL_MARKET_ADMIN];
export const validPathsPerGroup = {
  'MB-Trader': [RUN_LIST_PATH],
  'MB-Market_Admin': [FILE_DOWNLOADER_PATH]
};

export const defaultXDays = 1;
// ICON COLORS DIFFERENT CONSTANTS
export const cssStyleProps = {
  ICON_WIDTH: '10px',
  CENTER: 'center',
  MIDDLE: 'middle',
  SMALL: 'small',
  // AVAILABLE
  DISABLED: 'disabled',
};

export const cssColors = {
  GREEN: '#3dc528',
  RED: '#c5283d',
  ORANGE: '#e9724c',
  BLUE: '#69f',
  YELLOW : '#f0ad4e'

};
export const ToolTipStatusColors = {
  AVAILABLE_TT_COLOR: cssColors.GREEN,
  NOT_AVAILABLE_TT_COLOR: cssColors.RED,
  ENVIRONMENT_COLOR: cssColors.YELLOW
};
export const ToolTipDisplayMsgs = {
  AVAILABLE: 'available',
  NOT_AVAILABLE: 'not available',
  DOWNLOAD: 'Download',
  UPLOAD: 'Upload',
  NOTE: "Note",
  RESULTS: 'Results',
  NOTRADE: 'No trades were generated from your orders',
};
export const iconColors = {
  ICON_COLOR_GREEN: cssColors.GREEN,
  ICON_COLOR_RED: cssColors.RED,
  ICON_COLOR_AMBER: cssColors.ORANGE,
  ICON_COLOR_BLUE: cssColors.BLUE,

};
export const orderUploadMsgsSeverity = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
  IGNORE: 'IGNORE',
};
export const uploadAndDownloadStates = {
  HISTORY_STATE: 'HISTORY',
  PROCEED_STATE: 'PROCEED',
  SUCCESS_STATE: 'SUCCESS',
  ERROR_STATE: 'ERROR',
  TRADE_REPORT_SUCCESS_STATE: 'TRADE_REPORT_SUCCESS',
  TRADE_REPORT_ERROR_STATE: 'TRADE_REPORT_ERROR',
  NEW_STATE: 'NEW_STATE',
  FAILED_STATE: 'FAILED',
  VALIDATION_FAILED_STATE: 'VALIDATION_FAILED',
};

export const runStates = {
  FINALISED: 'Finalised',
  CLOSED: 'Closed'
};

export const UploadMsgHeader = ['Time', 'Description', 'Context'];

export const EMPTY_LITERAL = '';

export const DownloadType = {
  MA_DOWNLOAD: 'MA',
  USER_DOWNLOAD: 'USER'
};

export const buildVersionUrl = `${window.location.origin}/version.json`

export const DisplayMessages = {
  ORDER_UPLOAD_SUCCESS : 'Order sheet uploaded.',
  ORDER_UPLOAD_FAILURE : 'Unable to upload order sheet. \n Please contact Matchbook Sales.',
  ORDER_UPLOAD_RUN_CLOSED : 'Order sheet cannot be uploaded as run is closed.',
  ORDER_DOWNLOAD_SUCCESS : 'Order sheet downloaded.',
  ORDER_DOWNLOAD_FAILURE : 'Unable to download order sheet. \n Please contact Matchbook Sales.',
  ORDER_DOWNLOAD_RUN_CLOSED : 'Order sheet cannot be downloaded as run is closed.',
  TRADEREPORT_DOWNLOAD_SUCCESS : 'Trade report downloaded.',
  TRADEREPORT_DOWNLOAD_FAILURE : 'Trade report not available for download.',
  ORDER_SHEET_PROCESSED  : 'Order sheet processed.',
  ORDER_SHEET_NOT_PROCESSED  : 'Order sheet not processed.'

}


